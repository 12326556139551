import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Carousel,
  Button,
  Container,
  Row,
  Col,
  Accordion,
  Form,
  Nav,
  Tab,
} from "react-bootstrap";
import Cookies from "js-cookie";
import StarBearImage from "./img/star_bear.png";
import StarBearDefaultImage from "./img/star_bear_default.png";
import ProductIcon from "./ProductIcon";

const ReviewsSection = ({ products, reviews, setReviews }) => {
  const [selectedProduct, setSelectedProduct] = useState("");
  const [hoverRating, setHoverRating] = useState(0);
  const [selectedRating, setSelectedRating] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelectChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleMouseEnter = (rating) => {
    setHoverRating(rating);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (rating) => {
    setSelectedRating(rating);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      firstName,
      orderId,
      productId: selectedProduct,
      rating: selectedRating,
      review: reviewText,
    };

    try {
      const response = await fetch(
        "https://bmbserverprod.azurewebsites.net/api/reviews",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const newReview = {
          firstName,
          productId: selectedProduct,
          rating: selectedRating,
          review: reviewText,
        };
        setReviews((prevReviews) => [...prevReviews, newReview]);
        setFirstName("");
        setOrderId("");
        setSelectedProduct("");
        setSelectedRating(0);
        setReviewText("");
        setErrorMessage("");
      } else {
        const error = await response.text();
        setErrorMessage("Error submitting review: " + error);
      }
    } catch (error) {
      setErrorMessage("Error submitting review: " + error.message);
    }
  };

  return (
    <section className="py-5" id="reviews">
      <Container className="px-4 px-lg-5 mt-5">
        <Tab.Container defaultActiveKey="reviews">
          <Nav variant="tabs" id="myTab" role="tablist">
            <Nav.Item role="presentation">
              <Nav.Link
                eventKey="reviews"
                id="reviews-tab"
                role="tab"
                aria-controls="reviews"
                aria-selected="true"
              >
                <h3>Reviews</h3>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item role="presentation">
              <Nav.Link
                eventKey="write-reviews"
                id="write-reviews-tab"
                role="tab"
                aria-controls="write-reviews"
                aria-selected="false"
              >
                <h3>Write a Review</h3>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="bg-light" id="myTabContent">
            <Tab.Pane
              eventKey="reviews"
              className="fade show"
              id="reviews"
              role="tabpanel"
              aria-labelledby="reviews-tab"
            >
              <Row className="gx-4 gx-lg-5 align-items-center">
                <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
                  {reviews.length === 0 ? (
                    <p>
                      There are currently no reviews for this product. Be the
                      first to write a review!
                    </p>
                  ) : (
                    reviews.map((review, index) => (
                      <div key={index} className="review">
                        <h4>{review.firstName}</h4>
                        <div className="d-flex small text-warning">
                          {[...Array(review.rating)].map((_, i) => (
                            <img
                              src={StarBearImage}
                              class="img-tiny"
                              alt="Blue moon bear star rating icon"
                            />
                          ))}
                        </div>
                        <p>
                          <strong>Product:</strong>{" "}
                          {
                            products.find((p) => p.id === review.productId)
                              ?.name
                          }
                        </p>
                        <p>{review.review}</p>
                      </div>
                    ))
                  )}
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane
              eventKey="write-reviews"
              className="fade"
              id="write-reviews"
              role="tabpanel"
              aria-labelledby="write-reviews-tab"
            >
              <Row className="gx-4 gx-lg-5 align-items-center">
                <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
                  <h2 className="section-heading mb-4">
                    <span className="section-heading-upper">
                      We want to know what you think
                    </span>
                    <span className="section-heading-lower text-lowercase">
                      Write us a review
                    </span>
                  </h2>
                  <p>
                    Please refer to your order confirmation email for the
                    requested information. Reviews are only accepted from
                    verified purchases.
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="py-2">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="py-2">
                      <Form.Label>Order confirmation number</Form.Label>
                      <Form.Control
                        type="text"
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                        // placeholder="5 digit code"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="py-2">
                      <Form.Label>Item name</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedProduct}
                        onChange={handleSelectChange}
                        required
                      >
                        <option value="" disabled>
                          Select a product...
                        </option>
                        {products.map((product, index) => (
                          <option key={index} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="py-2">
                      <p>Rating:</p>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={star}
                          onMouseEnter={() => handleMouseEnter(star)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleClick(star)}
                          style={{
                            cursor: "pointer",
                            fontSize: "24px",
                            margin: "0 5px",
                          }}
                        >
                          {star <= (hoverRating || selectedRating) ? (
                            <img
                              src={StarBearImage}
                              alt="Star Bear"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ) : (
                            <img
                              src={StarBearDefaultImage}
                              alt="Star Bear"
                              style={{ width: "24px", height: "24px" }}
                            />
                          )}
                        </span>
                      ))}
                    </Form.Group>
                    <Form.Group className="py-2">
                      <Form.Label>Review</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                        rows={3}
                        required
                      />
                    </Form.Group>
                    {errorMessage && (
                      <Form.Group className="py-2">
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      </Form.Group>
                    )}
                    <Form.Group className="py-2">
                      <Button type="submit" className="btn btn-primary btn-lg">
                        Submit
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </section>
  );
};

// TODO: Derive ratings from reviews
const RelatedProducts = ({ currProduct, products, cart, setCart, reviews }) => {
  const relatedProducts = [];
  const productIds = new Set();

  currProduct.tags.forEach((tag) => {
    products.forEach((otherProduct) => {
      if (
        otherProduct.id !== currProduct.id &&
        otherProduct.tags.includes(tag)
      ) {
        if (!productIds.has(otherProduct.id)) {
          relatedProducts.push(otherProduct);
          productIds.add(otherProduct.id);
        }
      }
    });
  });

  return (
    <section className="py-5">
      <Container className="px-4 px-lg-5 mt-5">
        <h2 className="fw-bolder mb-4 text-white">Related products</h2>
        <Row className="gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-start">
          {relatedProducts.slice(0, 4).map((product) => (
            <ProductIcon
              product={product}
              cart={cart}
              setCart={setCart}
              reviews={reviews}
            />
          ))}
        </Row>
      </Container>
    </section>
  );
};

const Product = ({ products, cart, setCart, reviews, setReviews }) => {
  const [quantity, setQuantity] = useState(1);
  const { productId } = useParams();
  const product = products.find((p) => p.id === productId);

  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, [productId]);

  if (!product) {
    return <div>Product not found</div>;
  }

  const productReviews = reviews.filter(
    (review) => review.productId === product.id
  );
  const productRating =
    productReviews.length == 0
      ? 0
      : productReviews.reduce((sum, review) => sum + review.rating, 0) /
        productReviews.length;

  const handleQuantityChange = (event) => {
    setQuantity(Number(event.target.value));
  };

  const addToCart = () => {
    const existingProduct = cart.find((item) => item.id === product.id);

    let newCart;
    if (existingProduct) {
      newCart = cart.map((item) =>
        item.id === product.id
          ? { ...item, quantity: item.quantity + quantity }
          : item
      );
    } else {
      newCart = [
        ...cart,
        { id: product.id, price: product.default_price_id, quantity: quantity },
      ];
    }

    Cookies.set("cart", JSON.stringify(newCart), { expires: 7 });
    setCart(newCart);
  };

  return (
    <>
      <section className="py-5">
        <Container className="px-4 px-lg-5 my-5">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-6">
              <Carousel
                indicators={true}
                id="carouselExampleDark"
                className="carousel carousel-dark slide"
                data-bs-ride="carousel"
              >
                {product.images.map((image, index) => (
                  <Carousel.Item key={index} data-bs-interval="10000">
                    <img
                      className="d-block w-100"
                      src={image}
                      alt={`Slide ${index}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <div className="col-md-6 bg-light rounded p-2">
              <h1 className="display-5 fw-bolder">{product.name}</h1>
              <div className="fs-5 mb-5">
                <span>${product.default_price_amount}</span>
                <div className="d-flex small text-warning">
                  {[...Array(productRating)].map((_, i) => (
                    <img
                      src={StarBearImage}
                      class="img-tiny"
                      alt="Blue moon bear star rating icon"
                    />
                  ))}
                </div>
                {/* TODO: Reference reviews */}
                <a href="#reviews">
                  <span class="figure-caption">
                    ({productReviews.length} user reviews)
                  </span>
                </a>
              </div>
              <div className="d-flex mb-5">
                <Form.Select
                  className="text-center me-3"
                  id="inputQuantity"
                  value={quantity}
                  onChange={handleQuantityChange}
                  style={{ maxWidth: "5rem" }}
                >
                  {[...Array(10).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  className="btn btn-primary flex-shrink-0 btn-lg"
                  onClick={() => addToCart(product)}
                >
                  <i className="bi-cart-fill me-1"></i>
                  Add to Cart
                </Button>
              </div>
              <Accordion
                defaultActiveKey="0"
                id="accordionPanelsStayOpenExample"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Description</Accordion.Header>
                  <Accordion.Body>
                    <p className="lead">{product.description}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Size, Shape, and Feel</Accordion.Header>
                  <Accordion.Body>
                    <p className="lead">{product.sizeShapeAndFeel}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Materials</Accordion.Header>
                  <Accordion.Body>
                    <p className="lead">{product.materials}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Container>
      </section>
      <RelatedProducts
        currProduct={product}
        products={products}
        cart={cart}
        setCart={setCart}
        reviews={reviews}
      />
      <ReviewsSection
        products={products}
        reviews={reviews}
        setReviews={setReviews}
      />
    </>
  );
};

export default Product;
