import React, { useEffect } from "react";
import "./styles.css";
import BlueMoonBearFrontImage from "./img/blue_moon_bear_front.png";
import FabricImage from "./img/fabric.jpeg";
import BmbLogoImage from "./img/bmb_logo_bear_only_square.png";
import TeamImage from "./img/team.jpeg";

const About = () => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  return (
    <div>
      <section class="py-5">
        <div class="container px-4 px-lg-5 mt-5">
          <section className="page-section about-heading">
            <div className="container">
              <div className="row text-center">
                <h2 className="section-heading">
                  <span className="section-heading-lower text-white">
                    We value...
                  </span>
                </h2>
                <div className="col-sm-4 p-5">
                  <img
                    src={BlueMoonBearFrontImage}
                    className="img-fluid ratio ratio-1x1 rounded-circle mb-4"
                    alt="blue moon bear plushie image"
                  />
                  <div className="card text-white bg-primary mb-3">
                    <div className="card-body">
                      <h2 className="card-title">Simple shapes</h2>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 p-5">
                  <img
                    src={FabricImage}
                    className="img-fluid ratio ratio-1x1 rounded-circle mb-4"
                    alt="fabric image"
                  />
                  <div className="card text-white bg-info mb-3">
                    <div className="card-body">
                      <h2 className="card-title">Quality materials</h2>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 p-5">
                  <img
                    src={BmbLogoImage}
                    className="img-fluid ratio ratio-1x1 mb-4"
                    alt="picture of the blue moon bear logo"
                  />
                  <div className="card text-white bg-warning mb-3">
                    <div className="card-body">
                      <h2 className="card-title">Original designs</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="page-section about-heading">
            <div className="container">
              <div className="about-heading-content">
                <div className="row">
                  <div className="col-xl-9 col-lg-10 mx-auto">
                    <div className="bg-faded rounded p-5">
                      <h2 className="section-heading mb-4">
                        <span className="section-heading-upper">
                          A letter from the shop owner
                        </span>
                        <span className="section-heading-lower">
                          About the Shop
                        </span>
                      </h2>
                      <p>Hi there!</p>
                      <p>
                        Thank you for taking the time to learn about my small
                        business!
                      </p>
                      <p>
                        My name is Ashley Carroll and I am the founder and
                        single member of blue moon bear LLC. I started blue moon
                        bear LLC in 2024 as a creative outlet and means to share
                        my hobby with others. I hope you find as much joy and
                        love in these quirky, cute plushies & stickers as I do
                        in making them.
                      </p>
                      <p className="mb-0 text-end">
                        With gratitude,
                        <br />
                        Ashley
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="page-section about-heading">
            <div className="container">
              <img
                className="img-fluid rounded about-heading-img mb-3 mb-lg-0"
                src={TeamImage}
                alt="image of the sisters and significant others at a restaurant"
              />
              <div className="about-heading-content">
                <div className="row">
                  <div className="col-xl-9 col-lg-10 mx-auto">
                    <div className="bg-faded rounded p-5">
                      <h2 className="section-heading mb-4">
                        <span className="section-heading-upper">
                          Sisters and Significant Others
                        </span>
                        <span className="section-heading-lower">
                          The <span className="text-lowercase">bmb</span>{" "}
                          Support System
                        </span>
                      </h2>
                      <p>
                        Some special thanks are in order to (pictured from left
                        to right) Courtney Carroll, Emily Bumgardner, Zachary
                        Papanastasopoulous, Ashley Carroll (that's me!), Katelyn
                        Carroll, and Drew Howard. None of blue moon bear LLC
                        would be possible without this amazing support system!
                        Their advice on design and marketing has been
                        invaluable. An additional shoutout to Zach for his
                        contributions on the website. Thank you all so much!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default About;
