import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import StarBearImage from "./img/star_bear.png";
import "./styles.css";

const ProductIcon = ({ product, cart, setCart, reviews }) => {
  const productReviews = reviews.filter(
    (review) => review.productId === product.id
  );
  const productRating =
    productReviews.length == 0
      ? 0
      : productReviews.reduce((sum, review) => sum + review.rating, 0) /
        productReviews.length;
  const addToCart = () => {
    const existingProduct = cart.find((item) => item.id === product.id);

    let newCart;
    if (existingProduct) {
      newCart = cart.map((item) =>
        item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
      );
    } else {
      newCart = [
        ...cart,
        { id: product.id, price: product.default_price_id, quantity: 1 },
      ];
    }

    Cookies.set("cart", JSON.stringify(newCart), { expires: 7 });
    setCart(newCart);
  };

  return (
    <div className="col mb-5" key={product.id}>
      <div className="card h-100">
        <img
          className="card-img-top"
          src={product.images[0]}
          alt={product.name + " image"}
        />
        <div className="card-body p-4">
          <h5 className="fw-bolder text-center">{product.name}</h5>
          <div class="d-flex small justify-content-center my-2">
            {[...Array(productRating)].map((_, i) => (
              <img
                src={StarBearImage}
                class="img-tiny"
                alt="Blue moon bear star rating icon"
              />
            ))}
          </div>
          <div className="text-center">${product.default_price_amount}</div>
        </div>
        <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
          <div className="text-center">
            <Link
              className="btn btn-outline-primary btn-lg mt-auto mb-2"
              to={`/products/${product.id}`}
            >
              Details
            </Link>
            <div className="text-center">
              <button
                className="btn btn-primary btn-lg mt-auto"
                onClick={() => addToCart(product, cart, setCart)}
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductIcon;
