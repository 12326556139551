import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

const Faq = () => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  return (
    <section className="py-5">
      <div className="container px-4 px-lg-5 my-5">
        <div className="row gx-4 gx-lg-5 align-items-center">
          <div className="col-xl-9 col-lg-10 mx-auto">
            <Accordion alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Where can I buy blue moon bear products?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    Blue moon bear products can be purchased on our online shop.
                    Click <Link to="/catalog">here</Link> to see our full
                    catalog.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Who owns and manages blue moon bear LLC? Who is making the
                  designs and products?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    Ashley Carroll owns and manages blue moon bear LLC. You can
                    read more about her and the shop{" "}
                    <Link to="/about">here</Link>.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Are these products handmade?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    Yes! Each plushie, sticker, etc. is made individually with
                    care and attention.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Do you do returns?</Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    At this time, product returns are not supported on our
                    online shop. However, if you believe there has been a
                    mistake with your order or the product you received is not
                    to your satisfaction, please don’t hesitate to reach out to
                    us. You may submit any inquiries{" "}
                    <Link to="/contact">here</Link> and we will get back to you
                    as soon as possible. Thank you!
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Why does the checkout take me to another page?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    We use a third-party payment provider, Stripe, to adhere to
                    industry-standard encryption protocols and safeguard
                    sensitive information. After you complete or cancel your
                    purchase, you will be redirected back to our shop website.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  How does blue moon bear LLC adopt sustainable practices?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    We make every effort to use materials with minimal
                    environmental impact, such as recyclable cardboard and paper
                    packaging. We also donate a portion of our sales to removing
                    C02 from the atmosphere. Please help us in our efforts by
                    recycling or reusing any packaging when possible.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Why is there no sales tax?</Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    We currently do not meet the regulatory thresholds at which
                    we are required to collect sales tax. Since we do not
                    collect sales tax, however, you may be obligated to report
                    and pay a use tax when you file your state income tax
                    return.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  How is my data and privacy protected? Do you use cookies?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="lead">
                    Your privacy and data security is important to us. We do not
                    store any of your payment information (i.e. credit card
                    number or mailing/billing address) in our databases.
                    Instead, Stripe, a well-established and verified payment
                    provider, handles the processing of this information using
                    industry-standard encryption protocols. The only thing we
                    may store is your order details, name, and email address.
                    <br />
                    We do use cookies to enable the tracking of which items you
                    have in your cart. We do not use any marketing, advertising,
                    analytic, third-party, or any other types of cookies. We do
                    not sell any information to third parties.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
