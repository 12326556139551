import React, { useEffect } from "react";
import Cookies from "js-cookie";
import "./styles.css";

const Cart = ({ products, cart, setCart }) => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  const updateCart = (updatedCart) => {
    Cookies.set("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
  };

  const incrementQuantity = (productId) => {
    const updatedCart = cart.map((item) =>
      item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
    );
    updateCart(updatedCart);
  };

  const decrementQuantity = (productId) => {
    const updatedCart = cart
      .map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity - 1 } : item
      )
      .filter((item) => item.quantity > 0);
    updateCart(updatedCart);
  };

  const startCheckout = async () => {
    try {
      const line_items = cart.map((item) => {
        const product = products.find((product) => product.id === item.id);
        return {
          price: product.default_price_id, // Assuming product has a price property
          quantity: item.quantity, // Assuming product has a quantity property
        };
      });
      let payload = JSON.stringify({ line_items: line_items });

      const response = await fetch(
        "https://bmbserverprod.azurewebsites.net/api/checkout/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: payload,
        }
      );

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.checkout_url;
      } else {
        console.error("Checkout failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className="py-5">
      <div className="container px-4 px-lg-5 mt-5">
        <div className="row d-flex justify-content-center my-4">
          <div className="card mb-4">
            <div className="card-header py-3">
              <h1 className="mb-0">Shopping Cart</h1>
            </div>
            {cart.length === 0 ? (
              <p>Your cart is empty</p>
            ) : (
              <div className="card-body">
                {cart.map((item) => (
                  <div className="row text-center" key={item.id}>
                    <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
                      <div>
                        {products.find((product) => product.id === item.id)
                          .images.length > 0 && (
                          <img
                            src={
                              products.find((product) => product.id === item.id)
                                .images[0]
                            }
                            className="w-100"
                            alt={item.name}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 mb-4 mb-lg-0">
                      <h2>
                        {
                          products.find((product) => product.id === item.id)
                            .name
                        }
                      </h2>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-lg-0 align-items-center">
                      <div className="d-flex justify-content-center mb-4">
                        <button
                          className="btn btn-primary mt-auto"
                          onClick={() => decrementQuantity(item.id)}
                        >
                          -
                        </button>
                        <p style={{ margin: "0 10px" }}>{item.quantity}</p>
                        <button
                          className="btn btn-primary mt-auto"
                          onClick={() => incrementQuantity(item.id)}
                        >
                          +
                        </button>
                      </div>
                      <p>
                        $
                        {(
                          products.find((product) => product.id === item.id)
                            .default_price_amount * item.quantity
                        ).toFixed(2)}
                      </p>
                    </div>
                    <hr className="my-4" />
                  </div>
                ))}
                <div className="text-end">
                  <p>Please allow two weeks for your order to be prepared and shipped. Thank you!</p>
                  <button
                    className="btn btn-primary btn-lg mt-auto"
                    onClick={startCheckout}
                  >
                    Start Checkout
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
